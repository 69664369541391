<template>
  <!--
    The view for the InstallationRelationsList-component.
    Displays all installation relations.
  -->
  <Portlet
    title="Relations"
    class="relations"
    icon="directions"
  >
    <template slot="buttons">
      <font-awesome-icon
        :class="['alt-pointer color-primary mr-3', { 'fa-spin' : reloadLoad}]"
        icon="sync-alt"
        style="vertical-align: middle; float: right;"
        @click="reload()"
      />
      <button 
        v-if="authenticationHasRole('scope_staff')"
        class="btn btn-sm btn-primary float-right mt-2 mb-2" 
        @click="showSidebarAdd"
      >
        <font-awesome-icon
          class="mr-1"
          icon="plus"
        />
        <span>
          {{ $t('addLink') }}
        </span>
      </button>
    </template>
    <InstallationRelationsList 
      ref="list"
      :installation-id="installationId"
      @reloadAuditLogs="reloadAuditLogs"
      @startLoading="startLoading"
    />

    <DatabaseAuditLogList
      v-if="authenticationHasRole('scope_staff')"
      ref="databaseAuditLogList"
      :installation-id="installationId"
      :is-installation-links="true"
      @reload="reload"
    />
  </Portlet>
</template>

<script>
import { authenticationMixin } from '@/mixins/authenticationMixin';

export default {
  name: "InstallationDetailInstallationRelations",
  components: {
    InstallationRelationsList: () => import('@/components/Installation/InstallationRelationsList.vue'),
    DatabaseAuditLogList: () => import('@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue')
  },
  mixins: [
    authenticationMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      reloadLoad: false
    }
  },
  methods: {
    showSidebarAdd () {
      this.$refs.list.addLink();
    },
    reload () {
      this.$refs.list.getLinkedInstallations();
    },
    reloadAuditLogs () {
      this.$refs.databaseAuditLogList.reloadAuditLogs();
    },
    startLoading (val) {
      this.reloadLoad = val;
    }
  }
}
</script>